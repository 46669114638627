<template>
  <div class="container">
    <!-- 任务状态 -->
    <div class="item-inline-block">
      <el-radio-group v-model="params.status" @change="getTaskList()">
        <el-radio-button :label="2">全部</el-radio-button>
        <el-radio-button :label="1">已启用</el-radio-button>
        <el-radio-button :label="0">已停用</el-radio-button>
      </el-radio-group>
    </div>

    <!-- 任务名称搜索框 -->
    <div class="item-inline-block">
      <el-input v-model="params.name" placeholder="请输入任务名称" suffix-icon="el-icon-search" clearable
        @input="params.name == '' ? getTaskList() : ''"
        @change="getTaskList()"
        @clear="getTaskList()">
      </el-input>
    </div>
    
    <!-- 刷新按钮 -->
    <div class="item-inline-block">
      <el-button @click="getTaskList(params.pageIndex)" icon="el-icon-search" type="primary">查询</el-button>
    </div>
  
    <!-- 添加任务 -->
    <el-button @click="$router.push({path: '/task/edit'})" 
      type="primary" class="el-icon-plus" style="float:right">新建任务</el-button>
    
    <!-- 任务列表 -->
    <el-table ref="multipleTable" class="table-list"
      :data="taskList"
      :header-cell-style="{'background': '#f9f9f9', 'text-align': 'center'}"
      :cell-style="{'text-align': 'center'}">
      <!-- 任务名称 -->
      <el-table-column prop="sort" label="任务名称">
        <template slot-scope="scope">{{ scope.row.task_name }}</template>
      </el-table-column>
      <!-- 发起人 -->
      <el-table-column prop="name" label="发起人">
        <template slot-scope="scope"><p class="goods-name">{{ scope.row.name }}</p></template>
      </el-table-column>
      <!-- 商品单价 -->
      <el-table-column label="任务目标">
        <template slot-scope="scope">
          <span>{{ scope.row | taskgoal }}</span>
        </template>
      </el-table-column>
      <!-- 创建时间/上架时间 -->
      <el-table-column label="任务时间">
        <template slot-scope="scope">
          {{ scope.row.start_time | dateFormat }}<br>
          {{ scope.row.end_time | dateFormat }}
        </template>
      </el-table-column>
      <!-- 是否启用 -->
      <el-table-column label="是否启用" width="80">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.status"
            :active-value="1" :inactive-value="0"
            active-color="#1a9afd" inactive-color="#999999"
            @change="changeTaskStatus(scope.row)"></el-switch>
        </template>
      </el-table-column>
      <!-- 发布、下架、删除 -->
      <el-table-column label="操作" width="210">
        <template slot-scope="scope">
          <span class="task-button" style="color: #ff0000" 
            @click="deleteTask(scope.row)">删除</span>
          <span class="task-button" 
            @click="$router.push({path: '/task/edit', query: {id: scope.row.id}})">编辑</span>
          <span class="task-button"
            @click="$router.push({path: '/task/chart', query: {id: scope.row.id}})">查看数据</span>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top: 20px">
      <div style="float:right;">
        <el-pagination layout="total, prev, pager, next, jumper"
          @current-change="getTaskList"
          :background="true"
          :current-page="params.pageIndex"
          :page-size="params.pageNumber"
          :total="params.pageRows">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      taskList:[],         // 商品列表
      params: {               // 查询条件
        name: '',
        status: 2,          // 商品状态
        pageIndex: 1,
        pageNumber: 8,
        pageRows: 0
      },
    }
  },
  mounted(){
    this.getTaskList();
  },
  methods: {
    // 获取任务列表
    async getTaskList(pageIndex){
      this.params.pageIndex = pageIndex || 1;
      let params = JSON.parse(JSON.stringify(this.params));
      if(params.status == 2) delete params.status;
      if(!params.name) delete params.name;
      delete params.pageRows;
      let res = await this.$ZGManager.getTaskList(params);
      if(res.status != 200) return;
      this.params.pageRows = res.data.total_rows;
      this.taskList = res.data.list;
    },

    // 删除任务
    async deleteTask(item){
      let dialog = await this.$confirm(`您确定要删除 “${item.task_name}” 吗?`, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });
      if(dialog != 'confirm') return;
      let res = await this.$ZGManager.deleteTask(item.id);
      if(res.status != 200) return;
      this.$message.success('删除成功');
      setTimeout(() => {
        this.getTaskList(this.params.pageIndex);
      }, 500);
    },

    // 调整任务状态
    async changeTaskStatus(item){
      let res = await this.$ZGManager.updateTask(item.id, {status: item.status});
      if(res.status != 200) return;
      this.$message.success('更新成功');
      setTimeout(() => {
        this.getTaskList(this.params.pageIndex);
      }, 500);
    }
  }
}
</script>

<style lang="scss" scoped>
.container{
  overflow: hidden;
}
.operation{
  font-size: 20px;
}
.table-list{
  width: 100%; 
  margin-top: 10px
}
.goods-logo{
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}
.goods-name{
  overflow:hidden; 
  text-overflow:ellipsis;
  display:-webkit-box; 
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2; 
}
.goods-weight{
  cursor: pointer;
  color: #6e87bd;
}
.task-button{
  padding: 0 3px;
  color: #1a9afd;
  cursor: pointer;
}
</style>